<!-- 登录 -->
<template>
  <div style="background: #2c2c2c; height: 100vh">
    <Header />

    <el-dialog
      title="手机验证"
      :visible.sync="dialogFormVisible"
      style="height:477px width:800px !important;"
    >
      <el-form
        @submit.native.prevent
        status-icon
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="绑定手机号：" :label-width="formLabelWidth">
          <input class="verification" v-model="phone" type="text" />
        </el-form-item>
        <el-form-item label="输入验证码：" :label-width="formLabelWidth">
          <input class="verification" v-model="code" type="text" />
          <button v-show="show" @click="sendcode()" class="verificationbtn">
            发送验证码
          </button>

          <button v-show="!show" :value="count" class="verificationbtn">
            {{ count }}
          </button>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <button class="sendcode" @click="check">完成</button>
      </div>
    </el-dialog>

    <div class="login-main-area">
      <div class="container login-inner border-box">
        <div class="clearfix">
          <div class="login-desc">
            <h2>数十万用户的信赖之选</h2>
            <p>68个政府企事业单位合作伙伴</p>
            <p>14年诚信供应商</p>
            <p>9项国家专利技术</p>
            <p>15项核心技术知识产权</p>
          </div>
          <div class="login-main-inner">
            <div class="login-content">
              <div
                v-show="isShow"
                id="qrcodeLogin"
                class="login-main pie-for-element qrcode-content"
              >
                <div class="login-mode-wrapper">
                  <div
                    data-ispasswordlogin="1"
                    @click="changeShow"
                    class="login-mode-inner"
                  >
                    <span class="login-mode-text inline-block tac">
                      密码登录
                    </span>
                    <span
                      class="icon-login-mode icon-password-login vam"
                    ></span>
                  </div>
                </div>
                <div class="login-title">用户登录</div>
                <div class="login-qrcode-wrapper">
                  <div class="login-qrcode-content">
                    <div class="login-qrcode-image">
                      <div
                        style="width: 160px; height: 160px"
                        id="wx-qrcode-image"
                        class="wx-qrcode-image"
                        v-loading="loading"
                        element-loading-text="二维码已过期"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.8)"
                      >
                        <img
                          :src="wx.qrCodeBase64"
                          style="width: 155px; height: 155px"
                          alt=""
                        />
                      </div>
                      <!-- <div  class="login-qrcode-mask tac hide">
                        <div class="hide">
                          <h3>二维码已失效</h3>
                          <button>刷新</button>
                        </div>
                        <span class="inline-block icon-qrcode-success"></span>
                      </div> -->
                    </div>
                    <div>
                      <span class="inline-block icon-scanning vam"></span>
                      <div class="inline-block sm-tips-content">
                        打开微信扫一扫登录<br />
                        <!-- <span class="sm-expire-time">120</span>秒后二维码失效 -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="go-reg">
                  还没有账号？立即<router-link to="/register"
                    >免费注册</router-link
                  >
                </div>
              </div>
              <!-- end -->

              <div
                id="passwordLogin"
                v-show="!isShow"
                class="login-main pie-for-element"
              >
                <div class="login-mode-wrapper">
                  <div class="login-mode-inner" @click="changeShow">
                    <span class="login-mode-text inline-block tac">
                      扫码登录
                    </span>
                    <span class="icon-login-mode icon-qrcode-login vam"></span>
                  </div>
                </div>

                <div class="login-mode-wrapper"></div>
                <div class="login-title">用户登录</div>
                <p class="error-info" id="errorInfo"></p>
                <form action="javascript:;" id="loginForm" method="post">
                  <input type="hidden" name="redirectURL" value="" />
                  <div class="login-form-item form-item-bg form-item-bg-user">
                    <input
                      type="text"
                      name="username"
                      placeholder="手机号 / 已认证邮箱"
                      id="userName"
                      class="input-focus-handle"
                      value=""
                      autocomplete="off"
                      v-model="tel"
                    />
                    <div
                      id="emailAutoComplete"
                      class="email-complete-list"
                    ></div>
                  </div>
                  <div
                    class="login-form-item form-item-bg form-item-bg-lock password-input-wrap"
                  >
                    <input
                      id="passwordInput"
                      type="password"
                      name="password"
                      placeholder="登录密码"
                      class="input-focus-handle"
                      autocomplete="off"
                      v-model="password"
                    />
                    <div class="caps-lock-remind" id="capsLockRemind">
                      大写锁定已打开
                    </div>
                  </div>
                  <div class="captcha-deck clearfix">
                    <div
                      class="login-form-item captcha-item form-item-bg form-item-bg-captcha"
                    >
                      <input
                        type="text"
                        name="captcha"
                        placeholder="验证码"
                        maxlength="6"
                        class="input-focus-handle"
                      />
                    </div>
                    <div class="captcha-box">
                      <img class="show-captcha" src="" alt="" />
                      <span class="refresh-captcha"></span>
                    </div>
                  </div>
                  <div class="login-options">
                    <router-link to="forgetpwd">
                      <a href="">忘记密码？</a>
                    </router-link>
                  </div>
                  <input
                    type="submit"
                    value="登 录"
                    id="loginSubmit"
                    class="login-submit default-transition default-font"
                    @click="Login"
                  />
                </form>
                <div class="go-reg">
                  还没有账号？立即<router-link to="/register"
                    >免费注册</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-change"></div>
      </div>
    </div>
    <div class="login-footer">
      <div class="container">
        <div class="foot-copyright">
          <p>
            Copyright © 2013-2024 i360.cn. All Rights Reserved. 无锡市泛亚资讯网络有限公司
            版权所有　泛亚企业服务平台　<a target="_blank" href="http://beian.miit.gov.cn">苏ICP备2022035803号-1</a>
          </p>
          <p></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header/index.vue";
import {
  getLogin,
  getQrcode,
  checkWx,
  bandWxphone,
  getCode,
  getWxurl,
} from "@/api/all.js";
import { setToken } from "@/utils/auth.js";
export default {
  components: {
    Header,
  },
  data() {
    return {
      loading: false,
      count: "",
      formLabelWidth: "120px",
      show: true,
      dialogTableVisible: false,
      dialogFormVisible: false,
      timer: null,
      timer2: null,
      tel: "",
      password: "",
      isShow: false,
      phone: "",
      code: "",
      wx: {
        sceneId: "",
        qrCodeBase64: "",
      },
    };
  },
  created() {
    window.removeEventListener("scroll", this.scrollToTop);
    this.WxLogin();
    getWxurl().then((res) => {
      console.log(res);
    });
  },
  methods: {
    WxLogin() {
      //生成二维码
      getQrcode(this.$route.query).then((res) => {
        clearInterval(this.timer);
        console.log(res, "------");
        this.loading = false;
        this.wx.sceneId = res.data.sceneId;
        this.wx.qrCodeBase64 = res.data.qrCodeBase64;
        //定时器用户是否登录
        this.timer = setInterval(() => {
          checkWx({ sceneId: this.wx.sceneId }).then((res) => {
            console.log(res.data);
            switch (res.data.status) {
              //第一种状态绑定手机
              case "BIND_PHONE":
                this.dialog(true);
                break;
              //第二种状态成功 登录并且跳转到主页
              case "SUCCESS":
                //成功清楚定时器
                clearInterval(this.timer);
                this.dialog(false);
                this.$message({
                  message: "登录成功",
                  type: "success",
                });
                setToken(res.data.loginResult.token);
                localStorage.setItem(
                  "username",
                  res.data.loginResult.people.name
                );
                localStorage.setItem(
                  "phone",
                  res.data.loginResult.people.phone
                );
                localStorage.setItem("islogin", true);
                this.$router.push("/");
                window.collectEvent("config", {
                  user_unique_id: localStorage.getItem("web_id"), //设置唯一用户ID
                });
                break;
              case "WAITING":
                //第三种等待 什么都不做
                break;
              //第四种状态 二维码过期 遮挡二维码 且能点击刷新二维码
              case "REFRESH_QRCODE":
                this.loading = true;
                this.$message({
                  message: "验证码已过期",
                  type: "warning",
                });
                clearInterval(this.timer);
                break;
              default:
                clearInterval(this.timer);
                break;
            }
            // if(res.data.status === 'BIND_PHONE'){
            //   this.dialog(true)
            // }
            // if(res.data.status === 'SUCCESS'){
            //   clearInterval(this.timer)
            //   this.dialog(false);
            //   this.$message({
            //   message: '登录成功',
            //   type: 'success'
            //   });
            //   setToken(res.data.loginResult.token)
            //   localStorage.setItem('username',res.data.loginResult.people.name);
            //   localStorage.setItem('phone',res.data.loginResult.people.phone);
            //   localStorage.setItem('islogin',true);
            //   this.$router.push('/')
            // }
          });
        }, 2000);
      });
    },
    //发送验证码
    sendcode() {
      getCode({ phone: this.phone }).then((res) => {
        if (res.code == 200) {
          if (res.code == 200) {
            // 自定义时间 TIME_COUNT 默认 60
            const TIME_COUNT = 60;
            if (!this.timer2) {
              this.count = TIME_COUNT;
              this.show = false;
              this.timer2 = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer2);
                  this.timer2 = null;
                }
              }, 1000);
            }
          }
          console.log(res);
        }
      });
    },
    //遮罩层关闭
    dialog(change) {
      (this.dialogFormVisible = change), (this.dialogFormVisible = change);
    },
    //关闭弹窗
    check() {
      bandWxphone({
        sceneId: this.wx.sceneId,
        phone: this.phone,
        code: this.code,
      }).then((res) => {
        if (res.data.status === "SUCCESS") {
          this.dialog(false);
        }
        if (res.data.status === "REFRESH_QRCODE") {
          this.dialog(false);
        }
      });
    },
    //切换登录模式
    changeShow() {
      this.isShow = !this.isShow;
    },
    //登录
    Login() {
      getLogin({
        account: this.tel,
        code: this.password,
        password: this.password,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: "登录成功",
            type: "success",
          });
          setToken(res.data.token);
          localStorage.setItem("username", res.data.people.name);
          localStorage.setItem("phone", res.data.people.phone);
          // 改变登录状态
          this.$store.commit("changeState");
          localStorage.setItem("islogin", true);
          this.$router.push("/");
          window.collectEvent("config", {
            user_unique_id: localStorage.getItem("web_id"), //设置唯一用户ID
          });
        } else {
          this.$message.error("登录失败");
        }
      });
    },
  },
  beforeDestroy() {
    this.$once("hook:beforeDestroy", () => {
      // console.log('结束监听');
      clearInterval(this.timer);
      clearInterval(this.timer2);
    });
  },
};
</script>

<style scoped>
body {
  background: #2c2c2c !important;
}
.login-main-area {
  margin-top: 0px;
}
.foot-copyright {
  background: #2c2c2c;
  border-top: none;
}
.login-desc {
  margin-top: 80px;
}
.login-main-inner {
  margin-top: 80px;
}
.top-header {
  position: absolute;
}
.verificationbtn {
  border: 1px solid #b60005;
  color: #b60005;
  background-color: #ffffff;
  border-radius: 2px;
  width: 107px;
  height: 36px;
}
.verification {
  border: 1px solid #cfcfcf;
  border-radius: 2px;
  width: 258px;
  height: 36px;
  margin-right: 16px;
}
.sendcode {
  width: 107px;
  height: 36px;
  background-color: #b60005;
  color: #ffffff;
  border-radius: 2px;
}
@import "../../assets/static/template/Home/Zkeys/PC/Static/statics/css/front/login.css";
</style>
